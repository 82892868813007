export default [{
    path: '/admin',
    component: "__base",
    children: [{
            // module: "ADMIN",
            path: "roles",
            component: "Permission/Roles.vue",
            meta: { title: "Roles", right: "permission_role" },
        },
        {
            // module: "ADMIN",
            path: "rights",
            component: "Permission/Rights.vue",
            meta: { title: "Rights", right: "permission_right" },
        },
        {
            // module: "ADMIN",
            path: "bots",
            component: "Conserve/BotMaster.vue",
            meta: { title: "Analysis", right: "conserve_bots" },
        },
        {
            // module: "ADMIN",
            path: "user",
            component: "User.vue",
            meta: { title: "Users", right: "user" },
        },
        {
            // module: "ADMIN",
            path: "client",
            component: "Client.vue",
            meta: { title: "Entities", right: "client" },
        },
        {
            // module: "ADMIN",
            path: "client/id/:id",
            component: "ClientDetails.vue",
            meta: { title: "Entity Details", right: "client" },
        },

        {
            // module: "TDS",
            path: "area",
            component: "Conserve/Area.vue",
            meta: { title: "Area Master", right: "conserve_area" },
        },
        {
            // module: "TDS",
            path: "inputparameter",
            component: "Conserve/InputParam.vue",
            meta: { title: "Input Parameters", right: "conserve_input_parameter" },
        },
        {
            // module: "TDS",
            path: "file",
            component: "Conserve/FileMaster.vue",
            meta: { title: "File Master", right: "conserve_file" },
        },
        {
            // module: "TDS",
            path: "verification",
            component: "Conserve/Verification.vue",
            meta: { title: "Verification Master", right: "conserve_verification" },
        },
        {
            // module: "TDS",
            path: "products",
            component: "Subscription/SubscriptionProduct.vue",
            meta: { title: "Subscription Product", right: "subscription_product" },
        },
        {
            // module: "TDS",
            path: "plans",
            component: "Subscription/SubscriptionPlan.vue",
            meta: { title: "Subscription Plan", right: "subscription_plan" },
        },

        {
            // module: "ADMIN",
            path: "*",
            component: "",
        },
    ],
}, ]